import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Pagination from '../components/CaseStudy/Pagination'
import Intro from '../components/CaseStudy/Intro'
import Main from '../components/CaseStudy/Main'

const CaseStudyTemplate = ({ data, pageContext, location }) => {
  const { previous, next } = pageContext
  const post = data.allPrismicCaseStudiesArticle.edges[0].node.data
  const heroCrop = data.heroCrop.edges[0].node.data

  let localImage = false
  if (post.hero.url && post.hero.localFile.childImageSharp) {
    localImage = post.hero.localFile.childImageSharp
    localImage.alt = post.hero.alt ? post.hero.alt : 'Feature Image'
  }
  return (
    <Layout location={location}>
      <SEO
        title={post.heading && post.heading}
        type="article"
        description={post.lead.text && post.lead.text}
        location={location}
        image={localImage && localImage.fluid && localImage.fluid.src}
      />

      <Intro post={post} localImage={localImage} heroCrop={heroCrop} />

      <Main post={post} />

      <div className="bg-blue text-white">
        <div className="wrapper pt-20 lg:pt-28">
          <Pagination next={next} previous={previous} />
        </div>
      </div>
    </Layout>
  )
}

CaseStudyTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const postQuery = graphql`
  query PostsByUid($uid: String!) {
    allPrismicCaseStudiesArticle(
      filter: { uid: { eq: $uid } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            heading
            hero {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              url
              alt
            }
            technologies {
              html
              text
            }
            work_completed {
              html
              text
            }
            lead {
              html
              text
            }
            body {
              html
              text
            }
            gallery {
              gallery_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, maxHeight: 855) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
                alt
              }
            }
          }
        }
      }
    }
    heroCrop: allPrismicCaseStudiesArticle(
      filter: { uid: { eq: $uid } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          data {
            hero {
              url
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CaseStudyTemplate
